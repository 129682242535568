import React from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Bold = ({ children }) => <span className="bold">{children}</span>;

const Text = ({ children }) => <p>{children}</p>;

const options = {
	renderMark: {
		[MARKS.BOLD]: (text) => <Bold>{text}</Bold>
	},
	renderNode: {
		[BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>
	}
};

export default function VitaContent({ language, biography }) {
	const { title, titleEnglish } = biography.fields;
	const biographyContent = biography.fields.content;
	const biographyContentEnglish = biography.fields.contentEnglish;
	return (
		<React.Fragment>
			<div className="headline">
				<h2 className="normal-headline">{language ? title : titleEnglish}</h2>
			</div>
			<div className="bio-text">
				<React.Fragment>
					{language ? (
						documentToReactComponents(biographyContent, options)
					) : (
						documentToReactComponents(biographyContentEnglish, options)
					)}
				</React.Fragment>
			</div>
		</React.Fragment>
	);
}
