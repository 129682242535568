import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import i18n from '../../i18n';

function NavStart({ t, language, onToggle }) {
	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	};

	const toggleGerman = () => {
		changeLanguage('de');
		onToggle(true);
	};

	const toggleEnglish = () => {
		changeLanguage('en');
		onToggle(false);
	};

	return (
		<div>
			<div className="navbar nav-desktop">
				<div className="nav-content">
					<h1>
						<a href="/">Rebecca Blanz | Sopran</a>
					</h1>
					<div class="navLinks">
						<a href="/" activeClassName="activeLink" className="li">
							Start
						</a>
						{/* <NavLink to="/vita" activeClassName="activeLink" className="li">
							Vita
						</NavLink> */}
						<a href="/vita" className="li">
							Vita
						</a>
						<a href="/media" activeClassName="activeLink" className="li">
							Media
						</a>
						<a href="/calendar" activeClassName="activeLink" className="li">
							{t('calendar')}
						</a>
						<a href="/contact" activeClassName="activeLink" className="li">
							{t('contact')}
						</a>
						<div className="language-switch language-switch-mobile li">
							<a className={language ? 'active-language' : null} onClick={() => toggleGerman()}>
								DE
							</a>
							<a className={language ? null : 'active-language'} onClick={() => toggleEnglish()}>
								EN
							</a>
						</div>
					</div>
					<div className="language-switch language-switch-desktop">
						<a className={language ? 'active-language' : null} onClick={() => toggleGerman()}>
							DE
						</a>
						<a className={language ? null : 'active-language'} onClick={() => toggleEnglish()}>
							EN
						</a>
					</div>
					<div className="burger">
						<div className="line line-1" />
						<div className="line line-2" />
						<div className="line line-3" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default withNamespaces()(NavStart);
