import React from 'react';


function Start() {
    return(
	<div>
		<div className="wrapper" id="start-section">	
		</div>
		<div className="quote">
			<h2>„Eine sehr gewandte, elegante, zudem musikalisch ausgesprochen sensible Darstellerin mit einer gehörigen Portion Esprit - und angenehm gefärbter Sopranstimme.“</h2>
			<p>Die Rheinpfalz: Gábor Halasz</p>
		</div>
	</div>
    );
}

export default Start;
