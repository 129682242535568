import './Styles/App.scss';
import Nav from './Components/Common/Nav';
import NavStart from './Components/Common/NavStart';
import Start from './Pages/Start';
import Vita from './Pages/Vita';
import Media from './Pages/Media';
import Contact from './Pages/Contact';
import Footer from './Components/Common/Footer';
import FooterStart from './Components/Common/FooterStart';
import Calendar from './Pages/Calendar';
import Imprint from './Pages/Imprint';
import { Switch, Route } from 'react-router-dom';
import { client } from './client';
import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import i18n from './i18n';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import Privacy from './Pages/Privacy';

function App() {
	const [ events, setEvents ] = useState([]);
	const [ biography, setBiography ] = useState([]);
	const [ germanLanguage, toggleGermanLanguage ] = useState(true);

	useEffect(() => {
		if (localStorage.getItem('i18nextLng') === 'de') {
			toggleGermanLanguage(true);
		} else {
			toggleGermanLanguage(false);
		}

		client
			.getEntries()
			.then((response) => {
				let events = [];
				let biography = [];
				response.items.map((item, index) => {
					let actualItem = item.sys.contentType.sys.id;
					if (actualItem === 'event') {
						events.push(item);
					} else if (actualItem === 'biography') {
						biography.push(item);
					}
					return null;
				});
				setEvents(events);
				setBiography(biography);
			})
			.catch(console.error);
	}, []);

	return (
		<div className="App">
			<Switch>
				<Route exact path="/">
					<NavStart language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)} />
					<CookieConsent
						location="bottom"
						buttonText="Zustimmen"
						cookieName="cookieHabermann"
						style={{ background: '#e8e8e8', marginLeft: '0px' }}
						buttonStyle={{
							color: ' #FFFFFF',
							fontSize: '13px',
							background: '#000000',
							padding: '7px 45px',
							margin: '20px 25px'
						}}
						expires={150}
					>
						<p style={{ marginBottom: '-10px', marginLeft: '10px', fontSize: '13px', color: '#000000' }}>
							Wir und einige unserer Partner setzen zu den in den Cookie-Richtlinien beschriebenen Zwecken<br />{' '}
							Cookies und ähnliche Technologien ein.<br />
							Sie willigen in den Einsatz solcher Technologien ein, indem Sie diesen Hinweis schließen.
						</p>
						<br />{' '}
						<span style={{ fontSize: '13px', color: '#000000', marginLeft: '10px' }}>
							<a href="/privacy" style={{ fontSize: '13x', color: ' #000000' }}>
								Mehr erfahren
							</a>
						</span>
					</CookieConsent>
					<Start />
					<FooterStart language={germanLanguage} />
				</Route>
				<Route exact path="/vita">
					<NavStart language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)} />
					<Vita biography={biography} language={germanLanguage} />
					<FooterStart language={germanLanguage} />
				</Route>
				<Route exact path="/media">
					<NavStart language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)} />
					<Media language={germanLanguage} />
					<Footer language={germanLanguage} />
				</Route>
		
				<Route exact path="/calendar">
					<NavStart language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)} />
					<Calendar events={events} language={germanLanguage} />
					<Footer language={germanLanguage} />
				</Route>
				<Route exact path="/contact">
					<NavStart language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)} />
					<Contact language={germanLanguage} />
					<FooterStart language={germanLanguage} />
				</Route>
				<Route exact path="/imprint">
					<NavStart language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)} />
					<Imprint language={germanLanguage} />
					<FooterStart language={germanLanguage} />
				</Route>
				<Route exact path="/privacy">
					<NavStart language={germanLanguage} onToggle={(lng) => toggleGermanLanguage(lng)} />
					<Privacy language={germanLanguage} />
					<FooterStart language={germanLanguage} />
				</Route>
			</Switch>
		</div>
	);
}

export default withNamespaces()(App);
