import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import $ from 'jquery';

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<App />
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);

const gsap = window.gsap;

window.onload = function() {
	gsap.from('.textbio', { opacity: 0, duration: 1, y: -50, delay: 0.4 });
	gsap.to('.textbio', { opacity: 1, duration: 1.2, y: 0, delay: 0.8 });
	gsap.to('.animation-bio', {
		background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
		duration: 1.3,
		delay: 0.4
	});
	gsap.to('.animation-bi', { filter: 'grayscale(100%)', duration: 0.8, delay: 0.7 });
	document.getElementById('checkloader').className = 'loaded';
};
$('.burger, .navbar .navlinks .li').click(function() {
	$('.navbar .nav-content .navLinks').toggleClass('nav-active');
	$('.navbar .nav-content .navLinks .li').toggleClass('nav-active');
	$('.navbar').toggleClass('nav-active');
	$(this).toggleClass('nav-active');
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
