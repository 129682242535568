import React, { Component } from 'react'
import Slider from 'react-slick'

export default class Media extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }
  render() {
    const settings = {
      infinite: true,
      speed: 700,
      autoplay: true,
      slidesToShow: 1,
    }

    return (
      <div>
        <div className='media-section'>
          <h2 className='normal-headline'>Media</h2>
          {/* Videos */}
          <div className='media-grid'>
            <iframe
              className='fadescroll fade-yt'
              src='https://www.youtube.com/embed/xXxDB0NBpsM'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen'
              allowfullscreen
            />
            <iframe
              className='fadescroll fade-yt'
              src='https://www.youtube.com/embed/lB3v_t0-1jU'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen'
              allowfullscreen
            />

            <iframe
              className='fadescroll fade-yt'
              src='https://www.youtube.com/embed/4hOmzaYQquo'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen'
              allowfullscreen
            />
          </div>
          <div className='spotify-section'>
            <div className='align-center'>
              <a
                href='https://open.spotify.com/album/0VShCmNX9UmSlv0zOko2of?si=0k9rWhQaTfmOwwtyggoWwg'
                target='_blank'
              >
                <img src='./img/spotify.png' alt='' />
              </a>

              <a
                href='https://www.youtube.com/channel/UCyx6bSJwKzJsEn1t3wjUi5Q'
                target='_blank'
              >
                <img src='./img/youtube.png' alt='' />
              </a>
            </div>
          </div>
          {/* Diskographie & Publikationen */}
          <div className='media-grid-2'>
            {/* Diskographie */}
            <div className='item' data-emergence='hidden'>
              <h3>{this.props.language ? 'Diskographie' : 'Discography'}</h3>
              <p className='desc'>
                Sämtliche Alben der letzten Zeit unter meiner Mitwirkung -{' '}
                <b>
                  {' '}
                  klicken Sie gerne das jeweilige Cover für weitere Informationen an!
                </b>
              </p>
              <div className='media-sub-grid'>
                <div className='item-sub'>
                  <a
                    href='https://www.jpc.de/jpcng/cpo/detail/-/art/carl-heinrich-reinecke-aschenbroedel/hnum/6096038'
                    target='_blank'
                  >
                    <img src='./img/disko-1.jpg' alt='' />
                  </a>
                  <p>Aschenbrödel, C. Reinecke Titelpartie</p>
                </div>
                <div className='item-sub'>
                  <a
                    href='https://www.jpc.de/jpcng/cpo/detail/-/art/carl-heinrich-reinecke-die-wilden-schwaene/hnum/5434255'
                    target='_blank'
                  >
                    <img src='./img/disko-2.jpg' alt='' />
                  </a>
                  <p>Die wilden Schwäne - C. Reinecke Ensemble, 2. Drossel</p>
                </div>
                <div className='item-sub'>
                  <a
                    href='https://www.jpc.de/jpcng/classic/detail/-/art/als-treibe-hier-ein-herz-zum-hafen/hnum/4307117'
                    target='_blank'
                  >
                    <img src='./img/disko-3.jpg' alt='' />
                  </a>
                  <p>
                    Als treibe hier ein Herz zum Hafen Droste-Vertonungen von Matthias
                    Bonitz
                  </p>
                </div>
                <div className='item-sub'>
                  <a
                    href='https://www.jpc.de/jpcng/classic/detail/-/art/gedichte/hnum/7797224'
                    target='_blank'
                  >
                    <img src='./img/disko-4.jpg' alt='' />
                  </a>
                  <p>Annette von Droste-Hülshoff Gedichtsvertonungen von Volker Güth</p>
                </div>
              </div>
            </div>

            {/* Publikationen */}
            <div className='item' data-emergence='hidden'>
              <h3>{this.props.language ? 'Publikationen' : 'Publications'}</h3>
              <p className='desc'>
                Die Publikation meiner künstlerischen Masterarbeit. Beschäftigung mit
                Konzerten für die menschliche Stimme und Konzertarien verschiedener
                Komponisten und Bezugnahme auf instrumentale Solokonzerte
                <br />
              </p>
              <a
                className='desc-a'
                href='https://www.morebooks.de/store/gb/book/die-menschliche-stimme-als-soloinstrument/isbn/978-620-2-49097-9'
                target='_blank'
              >
                Nähere Information und Bestellung
              </a>
              <br />
              <a
                className='desc-img'
                href='https://www.morebooks.de/store/gb/book/die-menschliche-stimme-als-soloinstrument/isbn/978-620-2-49097-9'
                target='_blank'
              >
                <img src='./img/publi-1.png' alt='' />
              </a>
            </div>
          </div>

          {/* Slider */}

          <div className='slider-content' data-emergence='hidden'>
            <h3>{this.props.language ? 'Impressionen' : 'Impressions'}</h3>
            <Slider ref={(c) => (this.slider = c)} adaptiveHeight={true} {...settings}>
              <div className='slider-box'>
                <img src='img/img-1.jpg' alt='Jakob Lenz' />
                <p>
                  Jakob Lenz (Stimme), Nationaltheater Mannheim 2021, © Christian Kleiner
                </p>
              </div>
              <div className='slider-box'>
                <img src='img/img-2.jpg' alt='Maximilian Borchardt' />
                <p>
                  Keine Stille außer der des Windes (Braut), Nationaltheater 2022, ©
                  Maximilian Borchardt
                </p>
              </div>
              <div className='slider-box'>
                <img src='img/img-3.jpg' alt='Christian Kleiner' />
                <p>Die Walküre (Ortlinde), Nationaltheater 2022, © Christian Kleiner</p>
              </div>
              <div className='slider-box'>
                <img src='img/img-4.webp' alt='Christian Kleiner' />
                <p>
                  Le Nozze di Figaro (Barbarina), Nationaltheater Mannheim 2023, copyright
                  Christian Kleiner
                </p>
              </div>
              <div className='slider-box'>
                <img src='img/img-5.webp' alt='Christian Kleiner' />
                <p>
                  Le Nozze di Figaro (Barbarina), Nationaltheater Mannheim 2023, copyright
                  Christian Kleiner
                </p>
              </div>
              <div className='slider-box'>
                <img src='img/img-6.webp' alt='Christian Kleiner' />
                <p>
                  Dido and Aeneas (Second Woman), Nationaltheater Mannheim 2024, copyright
                  Christian Kleiner
                </p>
              </div>
              <div className='slider-box'>
                <img src='img/img-7.webp' alt='Christian Kleiner' />
                <p>
                  Dido and Aeneas (Second Woman), Nationaltheater Mannheim 2024, copyright
                  Christian Kleiner
                </p>
              </div>
            </Slider>
            <div className='container-arrow'>
              <button className='prev slick-arrow' onClick={this.previous}>
                <svg
                  width='30'
                  height='24'
                  viewBox='0 0 30 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M0.984375 11.7188C0.984375 12.0156 1.10938 12.2812 1.34375 12.5L11.8594 23.0469C12.0938 23.2656 12.3438 23.3594 12.625 23.3594C13.2188 23.3594 13.6719 22.9219 13.6719 22.3438C13.6719 22.0625 13.5625 21.7812 13.375 21.5938L8.70312 16.875L4.07812 12.5938L7.71875 12.7656H28.9688C29.5625 12.7656 30 12.3281 30 11.7188C30 11.1094 29.5625 10.6719 28.9688 10.6719H7.71875L4.09375 10.8438L8.70312 6.5625L13.375 1.84375C13.5781 1.64062 13.6719 1.375 13.6719 1.07812C13.6719 0.5 13.2188 0.0625 12.625 0.0625C12.3438 0.0625 12.0781 0.171875 11.7812 0.46875L1.34375 10.9375C1.10938 11.1562 0.984375 11.4219 0.984375 11.7188Z'
                    fill='black'
                  />
                </svg>
              </button>

              <button className='next slick-arrow' onClick={this.next}>
                <svg
                  width='30'
                  height='24'
                  viewBox='0 0 30 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M30 11.7188C30 11.4219 29.875 11.1562 29.6406 10.9375L19.2031 0.46875C18.9062 0.171875 18.6406 0.0625 18.3594 0.0625C17.7656 0.0625 17.3281 0.5 17.3281 1.07812C17.3281 1.375 17.4219 1.64062 17.6094 1.84375L22.2812 6.5625L26.8906 10.8438L23.2656 10.6719H2.01562C1.42188 10.6719 0.984375 11.1094 0.984375 11.7188C0.984375 12.3281 1.42188 12.7656 2.01562 12.7656H23.2656L26.9062 12.5938L22.2812 16.875L17.6094 21.5938C17.4219 21.7812 17.3281 22.0625 17.3281 22.3438C17.3281 22.9219 17.7656 23.3594 18.3594 23.3594C18.6406 23.3594 18.8906 23.2656 19.125 23.0469L29.6406 12.5C29.875 12.2812 30 12.0156 30 11.7188Z'
                    fill='black'
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* Slider */}
        </div>
        <div class='elfsight-app-50e80e8e-8663-470d-a7a6-96e94a1e6063' />
      </div>
    )
  }
}
