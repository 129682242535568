import React from 'react';
import VitaContent from '../Components/Vita/VitaContent';

export default function Vita({ biography, language }) {
	return (
		<div>
			<section className="animation-bi">
				<section className="animation-bio" id="animation-bio">
					<div className="trigger">
						<p className="textbio">
							<p className="inside-bio">
								{biography.slice(0, 1).map((bio, index) => {
									return <VitaContent biography={bio} key={index} language={language} />;
								})}
							</p>
						</p>
					</div>
				</section>
			</section>
		</div>
	);
}
