import React from 'react'
import { Link } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'

function FooterStart({ language, t }) {
  return (
    <footer>
      <div className='footer-section'>
        <div className='item item-1'>
          <a href='/imprint'>{t('imprint')}</a>
          <a>,&nbsp;</a>
          <a href='/privacy'>{t('data & privacy')}</a>
        </div>
        <div className='item item-2'>
          <p>©{new Date().getFullYear()} by Rebecca Blanz</p>
        </div>
        <div className='item item-3'>
          {t('created by')}
          <a
            href='https://www.dreher-media.de/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src='img/Schriftzug-dm.png' alt='logo dreher.media' />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default withNamespaces()(FooterStart)
