import React from 'react';
import moment from 'moment';
import Event from './Event';
import { withNamespaces } from 'react-i18next';

function EventList({ events, language, t }) {
	const sortedEvents = events.sort((a, b) => moment(a.fields.begin).diff(b.fields.begin));
	return (
		<React.Fragment>
			{events.length !== 0 ? (
				sortedEvents.map((event, index) => {
					return <Event event={event} key={index} language={language} />;
				})
			) : (
				<div className="no-event-grid">
					<div className="no-events">{t('no upcoming events')}</div>
				</div>
			)}
		</React.Fragment>
	);
}

export default withNamespaces()(EventList);
